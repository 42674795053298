:root {
  --textColor: #000;
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 20px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 3px solid #000;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #000;
  background-color: #FFCD00;
}
.button:active {
  background-color: #FFB71B;
}
#home {
  height: 92px;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.cb-scroll-triggered--active #home {
  height: 60px;
}
.cb-layout2 #head {
  margin-top: calc(var(--spaceTotal) * 4);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.cb-layout3 #head,
.cb-layout4 #head,
.cb-layout6 #head {
  margin-top: calc(var(--spaceTotal) * 4);
}
.cb-layout5 #head {
  margin-top: calc(var(--spaceTotal) * 3);
}
.wrapper {
  padding-top: 188px;
}
.section--header {
  padding: 48px 0;
}
.cb-scroll-triggered--active .section--header {
  padding: 30px 0;
}
.container--mood {
  min-height: calc(100vh - 188px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc((var(--vh, 1vh) * 100) - 188px);
}
.container--mood .content {
  padding-right: 27.60736196%;
  box-sizing: border-box;
}
.container--moodtwo {
  min-height: calc(100vh - 460px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc((var(--vh, 1vh) * 100) - 460px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  isolation: isolate;
}
.cb-layout2 .container--moodtwo .area {
  padding-left: 25.69832402%;
  box-sizing: border-box;
}
.cb-layout3 .container--moodtwo .area .unit.slim {
  padding-left: 25.69832402%;
  box-sizing: border-box;
  position: relative;
}
.cb-layout3 .container--moodtwo .area .unit.slim:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 20.11173184%;
  background-size: 100%;
  background-position: 0% 10px;
  background-repeat: no-repeat;
  background-image: url(/images/designelement.svg);
}
.container--moodtwo .content {
  float: left;
  width: 39.36950147%;
  position: relative;
}
.container--moodtwo .mood {
  float: left;
  width: 59.53079179%;
  min-height: calc(100vh - 460px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc((var(--vh, 1vh) * 100) - 460px);
  padding: 10.11730205%;
  box-sizing: border-box;
  z-index: -1;
}
.container--moodtwo .mood:after {
  content: '';
  position: absolute;
  top: 0;
  left: 33.99014778%;
  height: 100%;
  width: 100vw;
  background-color: #FFB71B;
  z-index: -1;
}
.footarea {
  width: 101.22699387%;
  margin-left: -0.60606061%;
  display: flex;
  flex-wrap: wrap;
  padding: 90px 0;
  box-sizing: border-box;
}
.footpart {
  margin-left: 0.60606061%;
  margin-right: 0.60606061%;
}
.footpart.footpart--slim {
  width: 32.12121212%;
}
.footpart.init {
  padding-left: 90px;
  box-sizing: border-box;
}
.vcard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.vcard address {
  float: left;
  width: 50%;
  min-width: 170px;
  margin-bottom: 20px;
}
.vcard .contact {
  float: left;
  width: 50%;
  min-width: 170px;
}
.opentimes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.opentime {
  width: calc((100% / 3) - 10px);
}
.wrapper {
  font-size: 20px;
  line-height: 1.35;
}
h1 {
  font-size: 35px;
  line-height: 1.4;
}
.cb-layout1 h1 {
  font-size: 46px;
  line-height: 1.34782609;
}
.cb-layout2 h1 {
  font-size: 60px;
  line-height: 1.16666667;
}
h2 {
  font-size: 35px;
  line-height: 1.4;
}
h3 {
  font-size: 100px;
  line-height: 1.37;
}
h4 {
  font-size: 60px;
  line-height: 1.16666667;
}
h5 {
  font-size: 56px;
  line-height: 1.35714286;
}
.preTitle {
  font-size: 100px;
  line-height: 1;
  margin-bottom: 10px;
}
p.loud {
  font-size: 24px;
  line-height: 1.375;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 0.72254335%;
  margin-left: 0.72254335%;
  width: 98.55491329%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 31.88824663%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--five .area {
  width: 100%;
}
.section--five .area > .unit {
  margin-right: 0.60606061%;
  margin-left: 0.60606061%;
  width: 98.78787879%;
}
.section--five .area .cb-album .body,
.section--five .area .head,
.section--five .area .foot,
.section--five .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--five .area > .slim {
  width: 32.12121212%;
}
.section--five .area > .slim .head,
.section--five .area > .slim .foot,
.section--five .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--five .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--five .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 101.46627566%;
  margin-left: -0.73313783%;
}
.section--five .area {
  width: 101.22699387%;
  margin-left: -0.61349693%;
}
.section--one .area .seam.wide {
  padding-left: 9.97109827%;
  box-sizing: border-box;
}
.section--one .area .seam.slim {
  width: 98.55491329%;
  padding-left: 9.97109827%;
  padding-right: 29.9132948%;
  box-sizing: border-box;
}
.section--one .area .pure.slim .part.pict {
  padding: 0 13.60544218%;
  box-sizing: border-box;
}
.section--slider .area .unit {
  min-height: 600px;
}
.section--slider .area .unit .body {
  padding-left: 10.11730205%;
  padding-right: 50.58651026%;
  box-sizing: border-box;
}
.section--slider .area .unit .pict.part.tall:last-child {
  position: absolute;
  right: 0;
  top: 0;
  width: 39.29618768%;
  height: 100%;
  margin: 0;
}
.section--slider .area .unit .pict.part.tall:last-child * {
  height: 100%;
}
.section--slider .area .unit .pict.part.tall:last-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--five .area .wide .body {
  width: 101.22699387%;
  margin-left: -0.60606061%;
}
.section--five .area .wide .part {
  margin-left: 0.60606061%;
  margin-right: 0.60606061%;
}
.section--five .area .wide .part.tall {
  width: 65.45454545%;
}
.section--five .area .wide .part.tiny {
  width: 32.12121212%;
}
.section--five .area .wide .part.pict {
  height: 480px;
}
.section--five .area .wide .part.pict * {
  height: 100%;
}
.section--five .area .wide .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--four .area .unit {
  padding-left: 690px;
  box-sizing: border-box;
  min-height: 530px;
}
@media (max-width: 1250px) {
  .section--four .area .unit {
    padding-left: 480px;
    min-height: 420px;
  }
}
.section--four .area .unit .pict.tall:first-child {
  width: 530px;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}
@media (max-width: 1250px) {
  .section--four .area .unit .pict.tall:first-child {
    width: 420px;
  }
}
.section--four .area .unit h4 {
  margin-top: var(--spaceTotal);
}
.section--five .area .slim .part.pict.tall {
  height: 740px;
}
.section--five .area .slim .part.pict.tall * {
  height: 100%;
}
.section--five .area .slim .part.pict.tall img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--five .area .slim .part.pict.tiny {
  height: 480px;
}
.section--five .area .slim .part.pict.tiny * {
  height: 100%;
}
.section--five .area .slim .part.pict.tiny img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
/*# sourceMappingURL=./screen-large.css.map */